<template>
  <div class="products-list-page" id="listproducts">
    <div
      itemprop="haspart"
      itemscope
      itemtype="http://schema.org/WebPage"
      class="vld-parent"
    >
      <page-header :item="item" :overlay="false" />
      <loading :active="spinner && $route.params.page !== 'all'">
        <spinner :spinner="spinner">
          Se incarca produsele
          <template v-slot:noitems>Nu mai sunt produse de incarcat</template>
        </spinner>
      </loading>
      <slogan-livrare :slogan="slogan" :shipping="shipping" />
      <breadcrumb :breadcrumbs="categ.breadcrumbs" />
      <text-preview :backgroundw="isCategsGrid" />
      <template v-if="categ.children && categ.children.length > 0">
        <categs-grid v-if="categ.children" :categs="categ.children" />
      </template>
      <template v-else>
        <filter-products :totalProducts="totalProducts" />
        <mobile-filter
          v-if="imgFilter == true"
          @showFilter="showMobileFilter"
          :products="totalProducts"
        />
        <transition name="fade">
          <products-mobile-filter
            v-show="windowWidth < 768 && navbarFilterMobile"
            @closeMobileFilter="closeFilter"
          />
        </transition>
        <!-- <skeleton-products-grid /> -->
        <product-grid />
      </template>
      <CategDescription v-if="categ.description" :description="categ.description" />
      </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageHeader from "@/components/page_header/PageHeader.vue";
import SloganLivrare from "@/components/product_list/SloganLivrare.vue";
import TextPreview from "@/components/product_list/TextPreview.vue";
import FilterProducts from "@/components/product_list/FilterProducts.vue";
import ProductGrid from "@/components/product_list/ProductGrid.vue";
import CategsGrid from "@/components/product_list/CategsGrid.vue";
import CategDescription from "@/components/product_list/CategDescription.vue";
import MobileFilter from "@/components/filter/MobileFilter.vue";
import ProductsMobileFilter from "@/components/filter/ProductsMobileFilter.vue";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";
// import SkeletonProductsGrid from '@/components/blocks/skeleton/SkeletonProductsGrid.vue';
import Spinner from "@/components/blocks/Spinner.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    name: "ProductList",
    data() {
        return {
            navbarFilterMobile: false,
            windowWidth: window.innerWidth,
        };
    },
    components: {
        "page-header": PageHeader,
        "slogan-livrare": SloganLivrare,
        "text-preview": TextPreview,
        "product-grid": ProductGrid,
        FilterProducts,
        CategsGrid,
        CategDescription,
        MobileFilter,
        ProductsMobileFilter,
        Breadcrumb,
        Spinner,
        Loading,
        // SkeletonProductsGrid
    },

    watch: {
        navbarFilterMobile(isOpen) {
            if (!isOpen) {
                this.$store.dispatch("filter/actionImgFilter", true);
            }
        },
    },

    computed: {
        ...mapGetters("filter", ["imgFilter"]),

        categ() {
            // console.log(this.$store.getters["shop/category"]);
            return this.$store.getters["shop/category"];
        },
        item() {
            return { path: this.categ.header_image };
        },
        categIsLoad() {
            return this.$store.getters["shop/categoryIsLoad"];
        },
        slogan() {
            let message = "";
            if (this.categIsLoad && this.categ.transport_caption !== "0") {
                message = this.categ.transport_caption;
            }
            if (
                this.categIsLoad &&
                (this.categ.transport_caption === "" ||
                    this.categ.transport_caption === "0")
            ) {
                message = "Livrare in toata Romania";
            }
            return message;
        },
        shipping() {
            let transport = false;
            if (this.categIsLoad && this.categ.labels) {
                transport = this.categ.labels.some((elem) => {
                    return elem.code === "transport-gratuit";
                });
            }
            return transport;
        },
        isCategsGrid() {
            return this.categ.children?.length > 0 ? true : false;
        },

        totalProducts() {
            return this.$store.getters["shop/totalProducts"];
        },
        spinner() {
            return this.$store.getters["shop/spinner"];
        },

        filters() {
            const filters = this.$store.getters["shop/filters"].filter((filter) => {
                return filter.filter_type !== "list";
            });

            return filters;
        },
    },
    methods: {
        showMobileFilter() {
            this.navbarFilterMobile = true;
        },
        closeFilter() {
            this.navbarFilterMobile = false;

            this.scrollToTop();
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    unmounted() {
        // console.log("reset products Array");
        this.$store.dispatch("shop/resetProductsArray");
        this.$store.dispatch("shop/actCategoryIsLoad", false);
        this.$store.dispatch("filter/actionImgFilter", true);
    },
};
</script>

<style scoped>
.custom-container {
    min-height: 850px;
    font-weight: bold;
}
</style>

<style>
.custom-container {
}

@import "../../assets/css/list_products.css";
</style>
