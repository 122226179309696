<template>
    <div class="products-list-page" id="listproducts">
        <div itemprop="haspart" itemscope itemtype="http://schema.org/WebPage">
            <page-header :item="item" :overlay="false" />
            <slogan-livrare />
            <breadcrumb v-if="windowWidth < 768" :breadcrumbs="categ.breadcrumbs" />
            <transition name="fade">
                <navigation-filter-categ
                    v-if="imgFilter === false || windowWidth > 768"
                    :categories="categ.filter_categs"
                    @onSelect="selectedCateg"
                />
            </transition>
            <mobile-filter />
            <text-preview />
            <product-grid />
            <CategDescription :description="categ.description" />
        </div>
    </div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import SloganLivrare from "@/components/product_list/SloganLivrare.vue";
import TextPreview from "@/components/product_list/TextPreview.vue";
import NavigationFilterCateg from "@/components/filter/NavigationFilterCateg.vue";
import ProductGrid from "@/components/product_list/ProductGrid.vue";
import CategDescription from "@/components/product_list/CategDescription.vue";
import MobileFilter from "@/components/filter/MobileFilter.vue";
import { mapGetters } from "vuex";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb.vue";

export default {
    data() {
        return {
            windowWidth: window.innerWidth,
            isLoading: true,
        };
    },
    components: {
        PageHeader,
        SloganLivrare,
        TextPreview,
        NavigationFilterCateg,
        ProductGrid,
        CategDescription,
        MobileFilter,
        Breadcrumb,
    },
    computed: {
        ...mapGetters("filter", ["imgFilter"]),
        categ() {
            // console.log(this.$store.getters["shop/category"]);
            return this.$store.getters["shop/category"];
        },
        item() {
            return { path: this.categ.header_image };
        },
    },
    methods: {
        selectedCateg(categ) {
            const payload = {
                id: categ.id,
                slug: categ.slug,
            };
            this.$router.push({ name: "Blog", params: { slug: categ.slug } });
            this.$store.dispatch("blog/selectedCategory", payload);
        },
    },
};
</script>
