<template>
  <div class="products-list-page scroll-menu" id="listproducts">
    <div itemprop="haspart" itemscope itemtype="http://schema.org/WebPage">
      <page-header :item="item" :overlay="false" />

      <slogan-livrare />
      <transition name="fade" v-if="filterValues">
        <navbar-filter
          v-if="windowWidth > 768 && filterValues.length"
          :categories="filterValues"
          :class="{ sticky: scrollNavbar }"
        />
      </transition>
      <text-preview />
      <navbar-filter-mobile
        v-show="windowWidth < 768 && navbarFilterMobile"
        :categories="filterValues"
        :class="{ sticky: scrollNavbar }"
        @closeMobileFilter="closeFilter"
      />
      <mobile-filter @showFilter="showMobileFilter" />
      <products-list-filter />
      <CategDescription :description="categ.description" />
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import SloganLivrare from "@/components/product_list/SloganLivrare.vue";
import TextPreview from "@/components/product_list/TextPreview.vue";
import ProductsListFilter from "@/components/product_list/ProductsListFilter.vue";
import CategDescription from "@/components/product_list/CategDescription.vue";
import MobileFilter from "@/components/filter/MobileFilter.vue";
import NavbarFilter from "@/components/filter/NavbarListFilter.vue";
import NavbarFilterMobile from "@/components/filter/NavbarFilterListMobile.vue";
import { useStore, mapGetters } from "vuex";
import { computed, provide } from "vue";

export default {
  setup() {
    const { state, commit } = useStore();
    const filters = computed(() => {
      let allFilter = state.shop.filters;

      var isSelected = allFilter.find(({ filter_type }) => filter_type == "list");
      if (isSelected) {
        return isSelected;
      } else {
        return false;
      }
    });
    const filterValues = computed(() => {
      return filters?.value.values;
    });
    provide("filters", filters);

    return {
      filterValues,
      filters,
      state,
      commit,
    };
  },
  provide() {
    return {
      filters: computed(() => this.filters),
    };
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      isLoading: true,
      scrollNavbar: false,
      navbarFilterMobile: false,
    };
  },
  components: {
    PageHeader,
    SloganLivrare,
    TextPreview,
    CategDescription,
    MobileFilter,
    NavbarFilter,
    NavbarFilterMobile,
    ProductsListFilter,
  },
  computed: {
    ...mapGetters("filter", ["imgFilter"]),
    categ() {
      return this.$store.getters["shop/category"];
    },
    item() {
      return { path: this.categ.header_image };
    },
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 260) {
        this.scrollNavbar = true;
      } else {
        this.scrollNavbar = false;
      }
    },
    selectedCateg(categ) {
      const payload = {
        id: categ.id,
        slug: categ.slug,
      };
      this.$router.push({ name: "Blog", params: { slug: categ.slug } });
      this.$store.dispatch("blog/selectedCategory", payload);
    },
    showMobileFilter() {
      this.navbarFilterMobile = true;
    },
    closeFilter() {
      this.navbarFilterMobile = false;
      this.$store.dispatch("filter/actionImgFilter", true);
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import "../../assets/css/promotii.css";
@import "../../assets/css/list_products.css";

.default-bg {
  /* background-color: #f5f4f2; */
}

.promotion-page {
  background-color: #f5f4f2;
}
</style>
