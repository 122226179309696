<template>
  <template v-if="categ.id">
    <component :is="layoutList"></component>
  </template>
  <template v-if="categIsLoad && !categ.id">
    <page-header :overlay="false" />
    <div class="container custom-container">
      <div class="row">
        <div class="col-12">
          <h1 class="empty-cart-title">
            Aceasta categorie nu (mai) exista!<br />Incearca alte produse si categorii pe
            <router-link :to="{ name: 'Home' }">pagina principala</router-link>
          </h1>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import PageHeader from "@/components/page_header/PageHeader.vue";
import ProductList from "@/pages/product_list/ProductList.vue";
import ProductListMenu from "@/pages/product_list/ProductListMenu";
import ProductListScrollMenu from "@/pages/product_list/ProductListScrollMenu";

export default {
  components: {
    PageHeader,
    ProductListMenu,
    ProductList,
  },

  computed: {
    layoutList() {
      switch (this.categ.layout) {
        case "with_filter":
          return ProductListMenu;
        case "with_scroll_filter":
          return ProductListScrollMenu;
        default:
          return ProductList;
      }
    },
    filter_params() {
      return this.$store.getters["shop/filter_params"];
    },
    categ() {
      // console.log(this.$store.getters['shop/category']);
      return this.$store.getters["shop/category"];
    },
    selFromshop() {
      return this.$store.getters["shop/selFromShop"];
    },

    categIsLoad() {
      return this.$store.getters["shop/categoryIsLoad"];
    },
  },

  methods: {
    init(slug) {
      // const page = this.$route.params.page;
      // this.$store.dispatch('shop/actPage', page);
      this.$store.dispatch("shop/actionSpinner", true);

      if (slug === undefined) {
        this.$router.push({ name: "NotFound" });
      } else {
        this.$store.dispatch("shop/getCateg", slug).then(() => {
          // const id = response.id;
          this.$store
            .dispatch("shop/getFilters", slug)
            .then(() => {
              const url_filters = this.$route.params?.filters || [];
              const filters = this.$store.getters["shop/filters"];

              for (let url_filter of url_filters) {
                url_filter = url_filter.split("_");
                for (let filter of filters) {
                  if (url_filter[0] === filter.code) {
                    for (let i = 1; i < url_filter.length; i++) {
                      let index = i - 1;
                      let key = "";
                      if (filter.filter_type === "checkbox") {
                        key = "property[" + filter.id + "][" + index + "]";
                        // console.log(key);
                      }

                      let optionObj = "";
                      for (let option of filter.values) {
                        if (
                          option.value.toLowerCase() === url_filter[i] ||
                          option.url.toLowerCase() === url_filter[i]
                        ) {
                          optionObj = option;
                        }
                      }

                      let param = {
                        id: filter.id + "_" + optionObj.value,
                        key: key,
                        optionId: optionObj.id,
                        value: optionObj.slug,
                        filter: filter,
                        option: optionObj,
                      };

                      this.$store.dispatch("shop/addPropertyParam", param);
                    }
                  }
                }
              }
            })
            .then(() => {
              // dispatch pentru a arata descrierea corecta
              const categ_param = {
                key: "category",
                value: slug,
              };

              this.$store.dispatch("shop/addFilterParam", categ_param);

              let page =
                this.$route.params.page !== undefined ? this.$route.params.page : 1;

              this.filter_params.sort = this.$route.params.sortby !== undefined ? this.$route.params.sortby : this.categ.sort_type;

              const page_param = {
                key: "page",
                value: page,
                // value: 1,
              };
              this.$store.dispatch("shop/actPage", +page);
              this.$store.dispatch("shop/addFilterParam", page_param).then(() => {
                this.$store.dispatch("shop/getProducts", this.filter_params).then(() => {
                  this.$store.dispatch("shop/actionSpinner", false);
                  this.$store.dispatch("shop/actCategoryIsLoad", true);
                });
              });
            });
        });
      }
    },
  },

  created() {
    this.init(this.$route.params.slug);
  },

  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // For example, for a route with dynamic params `/foo/:id`, when we
    // navigate between `/foo/1` and `/foo/2`, the same `Foo` component instance
    // will be reused, and this hook will be called when that happens.
    // has access to `this` component instance.

    // const id = to.params.id
    // console.log(to.params);
    // console.log(from.params);

    if (to.params.slug !== from.params.slug) {
      // console.log("1 ramrua");
      this.$store.dispatch("shop/resetProductsArray");
      this.$store.dispatch("shop/actCategoryIsLoad", false);
      this.init(to.params.slug);
    } else if (this.selFromshop) {
      this.$store.dispatch("shop/changeSelFromShop", false);
    } else if (
      JSON.stringify(from.params?.filters) !== JSON.stringify(to.params?.filters)
    ) {
      // console.log("2 ramura");
      this.$store.dispatch("shop/resetProductsArray").then(() => {
        this.init(to.params.slug);
      });
    } else if (from.params.page !== undefined && to.params.page !== from.params.page) {
      // console.log("3 ramura pe aici intra", to.params.page);
      // console.log("from param page", from.params);
      this.$store.dispatch("shop/resetProductsArray").then(() => {
        this.init(to.params.slug);
      });
    }

    next();
  },
};
</script>
